<template>
    <KCourse loader-class="MBcont" course-id="4" title="Съемный костюм XIX в." :items="items">
        <div class="MBtextcont">
            <p>Дорогие друзья, это последнее занятие марафона по ботиночкам. Мы закончили с вами достаточно долгий и кропотливый процесс создания костюма для наших кукол. Я как всегда жду ваших работ!</p>
            <h3>9.1 Делаем основу туфелек</h3>
        </div>

        <VideoView video-id="ea0b8e05937c419f8011887beb1ab4ed"/>

        <div class="MBtextcont">
            <h3>9.2 Подошва, каблук, кант</h3>
        </div>

        <VideoView video-id="05921306192c4e6983963cb64f60d4fc"/>

        <div class="MBtextcont">
            <p>Знания без практики сами по себе ничего не значат, поэтому я очень благодарна всем, кто сделал свою куклу вместе с нами! Теперь вы владеете бесценным опытом и вдохновением от собственного результата ) Очень важно именно сейчас не расплескать этот настрой, этот творческий поток и начать двигаться дальше )</p>
        </div>

    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Подготовка", url:"/mycourses/costume/1"},
                { title: "Снимаем выкройку", url:"/mycourses/costume/2"},
                { title: "Моделируем", url:"/mycourses/costume/3"},
                { title: "Ткань. Раскрой", url:"/mycourses/costume/4"},
                { title: "Нижнее белье", url:"/mycourses/costume/5"},
                { title: "Шьем полочку", url:"/mycourses/costume/6"},
                { title: "Дошиваем платье", url:"/mycourses/costume/7"},
                { title: "Красное платье", url:"/mycourses/costume/8"},
                { title: "Делаем обувь", url:"/mycourses/costume/9"},
                { title: "Ручные швы", url:"/mycourses/costume/10"},
                { title: "Машинные швы", url:"/mycourses/costume/11"},
                { title: "Утюжка", url:"/mycourses/costume/12"},
            ],
        }
        }
    }
</script>